<template>
    <div class="container">
        <div class="details_one auto">
            <div class="details_one_left fl">
                <div class="gallery-top">
                    <!--<img :src="detailData.project_cover_url" alt="">-->
                    <img :src="bannerList[bannerIndex]" alt="">
                </div>
                <div class="swiper-father" style="margin-top: 20px">
                    <div class="pagination-btn pagination-btn-pre fl">
                        <img alt="" src="@/assets/images/icon_left@2x.png">
                    </div>
                    <div class="swiper-container gallery-thumbs fl">
                        <div class="swiper-wrapper">
                            <div :key="index" @click="()=>{bannerIndex=index}" class="swiper-slide cur"
                                 v-for="(item,index) in bannerList">
                                <img :src="item" alt="">
                            </div>
                        </div>

                    </div>
                    <div class="pagination-btn pagination-btn-next fl">
                        <img alt="" src="@/assets/images/icon_right@2x.png">
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="details_one_right fl">
                <h2>{{ detailData.project_name }}</h2>
                <p class="over3">{{ detailData.project_intro }}</p>
                <div class="price_box">
                    <div class="fl price_title">
                        <!-- {{ detailData.project_type === '1' ? '待遇' : detailData.project_type === '2' ? detailData.financing_stage : '预算' }} -->
                        {{ detailData.project_type === '1' ? this.$t('publish.treatment') : this.$t('publish.budget') }}
                    </div>
                    <div class="fl price_money" v-if="detailData.money">${{ detailData.money }}</div>
                    <div class="fl price_money" v-else>${{ detailData.exchange_cost }}</div>
                    <div class="fl price_hint">USD</div>
                    <div class="clearfix"></div>
                </div>
                <div class="guquan" v-if="detailData.project_type === '2'">{{ detailData.equity }}% {{
                    $t("publish.transferOfShares") }}
                </div>
                <div class="otherDes" v-if="detailData.project_type === '2'">{{ detailData.financing_appeal }}</div>
                <div class="information_box">
                    <div class="fl" style="margin-right: 60px;" v-if="detailData.view_count">
                        <img alt="" src="../../assets/images/icon_eye@2x.png" width="18">
                        {{ detailData.view_count }} {{ $t("project.view") }}
                    </div>
                    <div class="fl">
                        <img alt="" src="../../assets/images/icon_time@2x.png" width="18">
                        {{ detailData.create_time }}
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="btn_box" v-if="detailData.project_category == 'PT'">
                    <div @click.prevent="isFollowProject($event,1)" class="fl btn_box_one cur"
                         v-if="detailData.followed==0" v-preventReClick>
                        <img alt="" src="../../assets/images/icon_hart_w@2x.png" width="14px">
                        {{ $t("project.interested") }}
                    </div>
                    <div @click.prevent="isFollowProject($event,2)" class="fl btn_box_one_ban cur"
                         v-if="detailData.followed==1" v-preventReClick>
                        {{ $t("project.eliminate") }}
                    </div>

                    <div @click="$router.push({path:'/recommend', query: { projectId: $route.params.id,
                  projectName: detailData.project_name, supervisorId: detailData.project_supervisor_id,}})"
                         class="fl btn_box_two cur">
                        {{ $t("project.recommend") }}
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="details_two auto">
            <div class="details_two_left fl">
                <h2>{{ $t("project.details") }}</h2>
                <div style="margin-top: 20px" v-html="detailData.details"></div>
            </div>
            <div class="details_two_right fl"
                 v-if="detailData.is_hidden_issuer == '1' && detailData.project_category == 'PT'">
                <div class="photo fl">
                    <img :src="detailData.avatar" alt="">
                </div>
                <div class="fl people_msg">
                    <h2 class="over">{{ detailData.name }}</h2>
                    <p class="hint_one over">{{ detailData.company_name }} · {{ detailData.position }}</p>
                    <p class="over2 hint_two">{{ detailData.profile }}</p>
                    <div class="people_btn">
                        <div @click="$router.push({path: '/user/index',query:{userType:detailData.userType, talentId:detailData.initiator_id,}})"
                             class="fl cur">
                            {{ $t("project.buyNow") }}
                        </div>
                        <div @click.prevent="attention($event,1)" class="fl cur" v-if="detailData.focus==0"
                             v-preventReClick>
                            {{ $t("project.follow") }}
                        </div>
                        <div @click.prevent="attention($event,2)" class="fl cur" v-if="detailData.focus==1"
                             v-preventReClick>
                            {{ $t("project.followed") }}
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="details_two_right fl"
                 v-if="detailData.is_hidden_issuer == '1' && detailData.project_category == 'FT'">
                <div class="photo fl">
                    <img :src="detailData.avatar" alt="">
                </div>
                <div class="fl people_msg">
                    <h2 class="over">{{ detailData.name }}</h2>
                    <p class="hint_one over">{{ detailData.company_name }} · {{ detailData.position }}</p>
                    <p class="over2 hint_two">{{ detailData.profile }}</p>
                    <div class="people_btn">
                        <div @click="$router.push({path: '/user/index',query:{userType:detailData.userType, talentId:detailData.initiator_id,}})"
                             class="fl cur">
                            {{ $t("project.buyNow") }}
                        </div>
                        <div @click.prevent="attention($event,1)" class="fl cur" v-if="detailData.focus==0"
                             v-preventReClick>
                            {{ $t("project.follow") }}
                        </div>
                        <div @click.prevent="attention($event,2)" class="fl cur" v-if="detailData.focus==1"
                             v-preventReClick>
                            {{ $t("project.followed") }}
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>

    import Swiper from "swiper";
    import {projectDetails, followProject, browseProject} from "@/api/projectAbout";
    import {myVipProjectDetails} from "@/api/myProjectApi/publishedApi";
    import Loading from '@/components/loading'
    import {followTalent} from "@/api/memberApi";

    export default {
        data: () => ({
            detailData: {},
            bannerIndex: 0,
        }),
        computed: {
            bannerList() {
                return this.detailData?.project_banner?.split(',') ?? [];
            }
        },
        components: {
            Loading
        },
        created() {
            this.getData();
        },
        mounted() {
            let galleryThumbs = new Swiper('.gallery-thumbs', {
                spaceBetween: 10,
                slidesPerView: 5,
                loop: true,
                freeMode: true,
                loopedSlides: 5, //looped slides should be the same
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: '.pagination-btn-next',
                    prevEl: '.pagination-btn-pre',
                },
            });
        },

        methods: {
            //关注
            attention(event, type) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))//点击太频繁了

                }
                let formData = {
                    operateType: type,
                    talentId: this.detailData.initiator_id,
                    talentName: this.detailData.name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.detailData.focus = 1;
                            this.$message.success(this.$t('myProject.followMsg.followSuccess'))//关注成功
                        } else {
                            this.detailData.focus = 0;
                            this.$message.success(this.$t('myProject.followMsg.unfollowSuccess'))//取消关注成功
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            //是否愿意沟通
            isFollowProject(event, type) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))//点击太频繁了
                    return
                }
                let formData = {
                    initiatorId: this.detailData.initiator_id,
                    operateType: type,
                    projectId: this.$route.params.id,
                    projectName: this.detailData.project_name,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                followProject(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code === 200) {
                        if (type === 1) {
                            this.detailData.followed = 1;
                        } else {
                            this.detailData.followed = 0;
                        }
                        this.$message({message: res.msg, type: 'success'});
                    } else if (res.code === 400) {
                        this.$message({message: res.msg, type: 'warning'});
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            //请求详情数据
            getData() {
                let projectCategory = this.$route.query.projectCategory;
                if ("PT" === projectCategory) {
                    let formData = {
                        projectType: this.$route.query.type,
                        projectId: this.$route.params.id,
                        token: localStorage.getItem('token'),
                    }
                    this.isLoading = true;
                    projectDetails(formData).then((res) => {
                        this.isLoading = false;
                        this.detailData = res.data ?? {};
                        this.browse();
                    })
                } else if ("FT" === projectCategory) {
                    let formData = {
                        projectType: '2',
                        projectId: this.$route.params.id,
                        token: localStorage.getItem('token'),
                    }
                    this.isLoading = true;
                    projectDetails(formData).then((res) => {
                        this.isLoading = false;
                        this.detailData = res.data ?? {};
                        this.browse();
                    })
                } else {
                    /*this.$router.go(-1);
                    this.$message.warning(this.$i18n.locale == 'zh' ? "无法获取项目信息" : "Unable to get project information")*/
                    let formData = {
                        projectType: this.$route.query.type,
                        projectId: this.$route.params.id,
                        token: localStorage.getItem('token'),
                    }
                    this.isLoading = true;
                    projectDetails(formData).then((res) => {
                        this.isLoading = false;
                        this.detailData = res.data ?? {};
                        this.browse();
                    })
                }
            },
            //会员浏览项目处理
            browse() {
                let projectCategory = this.$route.query.projectCategory;
                if (projectCategory == null) {
                    projectCategory = "PT";
                }
                let formData = {
                    projectId: this.$route.params.id,
                    projectName: this.detailData.project_name,
                    projectCategory: projectCategory,
                    token: localStorage.getItem('token'),
                }
                console.log(formData)
                browseProject(formData).then((res) => {
                    console.log("会员浏览项目处理:" + res.msg);
                })
            },
        },
    }
</script>

<style scoped>
    .container {
        width: 100%;
        min-height: 100%;
    }

    .details_one {
        width: 1200px;
        margin-top: 70px;
    }

    .details_one_left {
        width: 695px;
        border-radius: 4px;
    }

    .details_one_right {
        width: 465px;
        margin-left: 37px;
    }

    .details_one_right > h2 {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 46px;
        color: #000000;
    }

    .details_one_right > p {
        width: 468px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
        margin-top: 26px;
    }

    .price_box {
        margin-top: 18px;
    }

    .price_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-right: 24px;
    }

    .price_money {
        font-size: 22px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        line-height: 22px;
        margin-right: 8px;
    }

    .price_hint {
        font-size: 18px;
        font-family: BentonSans;
        font-weight: normal;
        line-height: 24px;
        color: #000000;
    }

    .information_box {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 27px;
    }

    .information_box img {
        margin-bottom: 4px;
    }

    .btn_box {
        margin-top: 48px;
    }

    .btn_box_one {
        /*width: 235px;*/
        height: 50px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        padding: 0 10px;
    }

    .btn_box_one_ban {
        width: 220px;
        height: 50px;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #707070;

    }

    .btn_box_one img {
        margin-bottom: 4px;
    }

    .btn_box_two {
        /*width: 200px;*/
        height: 50px;
        border: 1px solid #707070;
        border-radius: 25px;
        box-sizing: border-box;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-left: 28px;
        color: #000000;
        padding: 0 10px;

    }

    .details_two {
        margin-top: 8px;
        width: 1200px;
        border-top: 1px solid #E5E5E5;
        padding-top: 42px;
    }

    .details_two_left {
        width: 695px;
        margin-right: 37px;
    }

    ::v-deep.details_two_left img {
        max-width: 100%;
    }

    .details_two_left > h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .details_two_right {
        width: 468px;
        height: 228px;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 28px 24px;
        box-sizing: border-box;
    }

    .photo {
        width: 67px;
        height: 67px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        overflow: hidden;
    }

    .photo img {
        width: 100%;
        height: 100%;
    }

    .people_msg {
        width: 329px;
        margin-left: 19px;
    }

    .people_msg h2 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
        margin-bottom: 13px;
    }

    .people_msg .hint_one {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-bottom: 7px;
    }

    .people_msg .hint_two {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
    }

    .people_btn {
        margin-top: 20px;
    }

    .people_btn div {
        width: 100px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 25px;
        box-sizing: border-box;
        line-height: 38px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
        margin-right: 22px;
    }

    .gallery-top {
        width: 695px;
        height: 460px;
        background: black;
        border-radius: 4px;
    }

    .gallery-top img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .gallery-thumbs {
        width: 609px;
        height: 90px;
        /*margin-top: 20px;*/
        position: relative;
    }

    .pagination-btn {
        width: 43px;
        height: 75px;
        position: relative;
        cursor: pointer;
    }

    .pagination-btn img {
        width: 9px;
        height: 17px;
        position: absolute;
        transform: translate(-50%, -50%); /*50%为自身尺寸的一半*/
        left: 50%;
        top: 50%;
    }

    .gallery-thumbs .swiper-slide {
        width: 114px;
        height: 75px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
    }

    .gallery-thumbs img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .gallery-thumbs .swiper-slide-active {
        border: 2px solid #FFFFFF;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .32);
        border-radius: 4px;
    }

    .guquan, .otherDes {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
        margin-top: 15px;
    }
</style>
